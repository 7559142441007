import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Loader } from '../../layOuts/Loader';
import moment from "moment";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { ErrorPopup } from '../../layOuts/errorPopup';
import $ from 'jquery';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../DataTableDemo.css';
import { Dropdown } from 'primereact/dropdown';
import ErrorHandlePopup from "../ErrorHandlePopup";

class SyncWithPlaybooks extends Component {
  constructor(props) {
    super(props);
    this.stepInput = React.createRef();
    this.state = {
      pageNo: 1,
      count: 0,
      pageSize: 10,
      first: 1,
      last: 10,
      next: true,
      prev: true,
      showLoader: false,
      showRefreshModal: false,
      collectionsData: [],
      isAxiosError: false,
      showPopup: false,
      selectedFile: null,
      collectionColumns: [
        {
          field: "collection_version",
          header: "Collection Version",
          frozen: false,
          value: "Collection Version",
          sortable: false,
          body: this.collectionVersion,
          style: { width: "30px", flexGrow: 1, minWidth: "20px" },
        },
        {
          field: 'models_supported',
          header: 'Supported Models',
          value: 'Supported Models',
          body: this.supportedModels,
          frozen: false,
          alignFrozen: 'left',
          sortable: false,
          style: { width: '170px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: 'models_interfaces',
          header: 'Supported Interfaces',
          value: 'Supported Interfaces',
          body: this.supportedInterfaces,
          frozen: false,
          sortable: false,
          style: { width: '170px', flexGrow: 1, minWidth: '130px' }
        },
        {
          field: "updated_date",
          header: "Updated on",
          frozen: false,
          value: "Updated on",
          sortable: false,
          body: this.updatedDate,
          style: { width: "70px", flexGrow: 1, minWidth: "50px" },
        },
      ]
    };
  };

  componentDidMount() {
    this.getPlaybooks();
  };

  supportedModels = (row) => {
    let allModels = [];
    allModels.push(JSON.parse(row.models_supported))
    return (
      <div>
        {row.models_supported !== '' ?
          <span className="dm-cell">
            {Object.entries(allModels[0]).map(([key, value]) => {
              return <div>
                <li>{`${key} : `}</li>
                <li>
                  {value.map(item =>
                    <ul className="dm-list"><li>{item}</li> </ul>)}
                </li>
              </div>
            })}
          </span>
          : '-'
        }
      </div>
    )
  };

  supportedInterfaces = (row) => {
    let allInterfaces = [];
    allInterfaces.push(JSON.parse(row.models_interface))
    return (
      <div>
        {row.models_interface !== '' ?
          <span className="dm-cell">
            {Object.entries(allInterfaces[0]).map(([key, value]) => {
              return <div>
                <li>{`${key} : `}</li>
                <li>
                  {value.map(item =>
                    <ul className="dm-list"><li>{item}</li> </ul>)}
                </li>
              </div>
            })}
          </span>
          : '-'
        }
      </div>
    )
  };

  collectionVersion = (row) => {
    return (
      <span
        className="date-time"
        title={row.collection_version}
      >
        {row.collection_version}
      </span>
    );
  };

  updatedDate = (row) => {
    return (
      <span
        className="date-time"
        title={this.getTime(row.updated_date)}
      >
        {this.getTime(row.updated_date)}
      </span>
    );
  };

  getTime = (time) => {
    let date = moment.utc(time);
    date = date.local();
    return date.format("DD MMMM YYYY hh:mm:ss A");
  };

  resetPopup = () => {
    this.setState({
      show: false,
      error: false,
    }, () => {
      $("#errorMessage").modal("hide");
      this.getPlaybooks();
    })
  };

  getPlaybooks = () => {
    this.setState({
      showLoader: true,
    });
    ApiCall(urlMapping.getCollectionsData(this.state.pageNo, this.state.pageSize), (response) => {
      const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
      const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
      this.setState({
        showLoader: false
      })
      if (parseInt(response?.response?.status) === 200) {
        this.setState({
          collectionsData: response?.results,
          next: response?.next ? false : true,
          prev: response?.previous ? false : true,
          first: first,
          last: last,
          count: response?.count
        })
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    })
  };

  setPerPage = (e) => {
    this.setState({
      pageNo: 1,
      pageSize: e.value
    }, () => this.getPlaybooks()
    )
  };

  nextPageChangeHandle = () => {
    this.setState({
      pageNo: this.state.pageNo + 1
    }, () => this.getPlaybooks()
    )
  };

  prevPageChangeHandle = () => {
    this.setState({
      pageNo: this.state.pageNo - 1
    }, () => this.getPlaybooks()
    )
  };

  firstPageChangeHandle = () => {
    this.setState({
      pageNo: 1
    }, () => this.getPlaybooks()
    )
  };

  lastPageChangeHandle = () => {
    this.setState({
      pageNo: Math.ceil(this.state.count / this.state.pageSize)
    }, () => this.getPlaybooks()
    )
  };

  handleselect = (e) => {
    const file = e.target.files[0];
    this.setState({ selectedFile: file });
  };

  openPopUp = () => {
    this.setState({
      showPopup: true,
      selectedFile: null
    }, () => {
      this.stepInput.current.value = null;
      $("#versionErrorMessage").modal("show");
    })
  };

  closePopUp = () => {
    this.setState({
      selectedFile: null
    }, () => {
      this.stepInput.current.value = null;
    }
    )
  };

  uploadDebianFile = () => {
    const formData = new FormData();
    formData.append("file", this.state.selectedFile);
    formData.append("collections", "True");
    this.setState({
      showLoader: true,
    });
    ApiCall(urlMapping.deviceListUpload(formData), (response) => {
      if (response?.success) {
        this.setState({
          showLoader: false,
          selectedFile: null,
          title: 'Collection Upload',
          message: "Collection file uploaded successfully!",
          show: true,
          error: false,
        }, () => {
          $("#errorMessage").modal("show");
        });
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({ showRefreshModal: true })
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false,
            title: 'Firmware Upload',
            message: response?.message || "Failed",
            show: true,
            error: true,
          }, () => {
            $("#errorMessage").modal("show");
          });
        }
      }
    })
  };

  render() {
    let colArray = this.state.collectionColumns;
    const dynamicColumns = colArray.map((col, i) => {
      return <Column
        key={col.field}
        field={col.field} resizable={col.resizable}
        sortable={col.sortable}
        header={col.header}
        body={col.body}
        style={col.style}
        alignFrozen={col.alignFrozen}
        frozen={col.frozen}
      />;
    });
    const template2 = {
      layout: 'RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink',
      'RowsPerPageDropdown': (options) => {
        const dropdownOptions = [
          { label: "10", value: 10 },
          { label: "20", value: 20 },
          { label: "30", value: 30 },
          { label: "40", value: 40 },
          { label: "50", value: 50 }
        ];
        return (
          <React.Fragment>
            <span
              className="p-mx-1"
              style={{
                color: 'var(--text-color)',
                userSelect: 'none'
              }}>Rows per page: </span>
            <Dropdown
              value={this.state.pageSize}
              options={dropdownOptions}
              onChange={(e) => this.setPerPage(e)}
            />
          </React.Fragment>
        );
      },
      'CurrentPageReport': (options) => {
        return (
          <span
            style={{
              color: 'var(--text-color)',
              userSelect: 'none',
              width: '120px',
              textAlign: 'center'
            }}>
            {this.state.first} - {this.state.last} of {this.state.count}
          </span>
        )
      },
      'FirstPageLink': () => {
        return (
          <button
            type="button"
            className=" p-link1"
            onClick={() => this.firstPageChangeHandle()}
            disabled={this.state.prev}
          >
            <img
              className={this.state.prev ? "p-link3" : "p-link2"}
              src={this.state.prev ? "/images/svg-icons/arrow-pre-disabled.svg" : "/images/svg-icons/arrow-pre.svg"}
              alt=""
            />
          </button>
        )
      },
      'PrevPageLink': () => {
        return (
          <button
            type="button"
            className=" p-link1"
            onClick={() => this.prevPageChangeHandle()}
            disabled={this.state.prev}
          >
            <i className="pi pi-chevron-left"></i>
          </button>
        )
      },
      'NextPageLink': () => {
        return (
          <button
            type="button"
            className="p-link1"
            onClick={() => this.nextPageChangeHandle()}
            disabled={this.state.next}
          >
            <i className="pi pi-chevron-right"></i>
          </button>
        )
      },
      'LastPageLink': () => {
        return (
          <button
            type="button"
            onClick={() => this.lastPageChangeHandle()}
            className=" p-link1"
            disabled={this.state.next}
          >
            <img
              className={this.state.next ? "p-link3" : "p-link2"}
              src={this.state.next ? "/images/svg-icons/arrow-next-disabled.svg" : "/images/svg-icons/arrow-next.svg"}
              alt=""
            />
          </button>
        )
      }
    };

    return (
      <>
        {this.state.showLoader && <Loader />}
        <div className="tab-content" id="pills-tabContent">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="pills-ma-playbook"
              role="tabpanel"
              aria-labelledby="pills-ma-playbook-tab"
            >
              <div className="white-bg">
                <div className="ta-box set-pos mb-0">
                  <div className="action-btn d-flex align-items-center">
                    <button
                      onClick={() => this.getPlaybooks()}
                    >
                      <img src="/images/svg-icons/refreshing.svg" alt="" />{" "}
                      Refresh
                    </button>
                    <button onClick={() => this.openPopUp()}>Browse for Sync</button>
                  </div>
                </div>
                <div className="card swpb">
                  <DataTable
                    value={this.state.collectionsData}
                    scrollable scrollHeight="400px"
                    loading={this.state.loading}
                    removableSort
                    resizableColumns
                    columnResizeMode="fit"
                    showGridlines
                    paginator
                    paginatorTemplate={template2}
                    first={this.state.firstDefault}
                    rows={this.state.pageSize}
                    paginatorClassName="p-jc-end"
                    dataKey="models_supported"
                    scrollDirection="both"
                    className="p-mt-3">
                    {dynamicColumns}
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.showPopup && (
            <div
              className="modal fade errorMessage"
              id="versionErrorMessage"
              tabIndex="-1"
              data-backdrop="static"
              role="dialog"
              aria-labelledby="errorMessageTitle"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header bg-white border-0">
                  </div>
                  <div className="s-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      style={{ paddingRight: "20px" }}
                      onClick={() => this.closePopUp()}>
                      <img
                        src="/images/svg-icons/cancel.svg"
                        alt=""
                        height="15px"
                        width="15px"
                      />
                    </button></div>
                  <div className="modal-body">
                    <div className="error-msg">
                      <span className="error-icon">i</span>
                      <div className="txt1">Do you want to upload collections?</div>
                      <input
                        className="f1 mt-2"
                        style={{ marginLeft: "4.5rem", cursor: "pointer" }}
                        type="file"
                        accept={".zip"}
                        ref={this.stepInput}
                        onChange={(e) => this.handleselect(e)}
                      />
                    </div>
                    {
                      (
                        this.state.selectedFile === undefined ||
                        this.state.selectedFile === null ||
                        !this.state.selectedFile?.name?.includes('.zip')
                      )
                      &&
                      <div
                        className="d-flex justify-content-center red-txt mt-3"
                      >
                        Please upload a ZIP file!
                      </div>
                    }
                  </div>
                  <div className="modal-footer border-top-0 justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                      disabled={
                        this.state.selectedFile === undefined ||
                        this.state.selectedFile === null ||
                        !this.state.selectedFile?.name?.includes('.zip')
                      }
                      onClick={() => this.uploadDebianFile()}
                    > Sync
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {
          this.state.show &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            reset={() => this.resetPopup()}
          />
        }
        {
          this.state.isAxiosError &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
        {
          this.state.showRefreshModal &&
          <ErrorHandlePopup />
        }
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    open: state.displaySidebarMenu.openSide,
    userRole: String(state.auth.role)
  };
};

export default withRouter(connect(mapStateToProps)(SyncWithPlaybooks));
