/* eslint-disable no-eval */
/* eslint-disable array-callback-return */
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from '../../layOuts/SideMenu';
import Header from '../../layOuts/Header';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import moment from 'moment';
import DataTable from "react-data-table-component";
import * as Action from '../../redux/actionTypes';
import UpdateFirmware from './updateFirmware';
import $ from 'jquery';
import ErrorHandlePopup from '../ErrorHandlePopup';
import { ErrorPopup } from '../../layOuts/errorPopup'

class DeviceFirmware extends Component {
    constructor(props) {
        super(props);
        this.stepInput = React.createRef();
        this.state = {
            showRefreshModal: false,
            firmwareData: [],
            firmwareVersionData: [],
            pageNo: 1,
            pageSize: 10,
            count: 0,
            deviceModel: '',
            deviceModelFilter: [],
            firmwareVersion: '',
            showFilter: false,
            filterEnabled: false,
            selectedData: [],
            showIcon: false,
            isAxiosError: false,
            columns: [
                {
                    name: "Name",
                    selector: "fw_name",
                    value: "Name",
                    center: true,
                    cell: (row) => (<span title={row?.fw_name} style={{ width: 'auto' }}>
                        {row?.fw_name}</span>
                    ),
                },
                {
                    name: "Source Version",
                    selector: "source_version",
                    value: "Source Version",
                    center: true,
                    cell: (row) => (<span title={row?.source_version} style={{ width: 'auto' }}>
                        {row?.source_version}</span>
                    ),
                },
                {
                    name: "Target Firmware",
                    selector: "fw_version",
                    value: "Firmware Version",
                    center: true,
                    cell: (row) => (<span title={row?.fw_version} style={{ width: 'auto' }}>
                        {row?.fw_version}</span>
                    ),
                },
                {
                    name: "Device Model",
                    selector: "device_models",
                    value: "Device Model",
                    // minWidth: '100px',
                    cell: (row) => (
                        <span className="dm-cell" style={{ width: 'auto' }}>
                            <ul className="dm-list">
                                <li
                                    title={eval(row?.device_models)?.toString()?.split(',')?.[0]}>
                                    {eval(row?.device_models)?.toString()?.split(',')?.[0]}
                                </li>
                                {eval(row?.device_models)?.toString()?.split(',')?.length > 1 &&
                                    <li title={eval(row?.device_models)?.toString()?.split(',')?.[1]}>
                                        {eval(row?.device_models)?.toString()?.split(',')?.[1]}
                                    </li>
                                }
                            </ul>
                            {eval(row?.device_models)?.toString()?.split(',')?.length > 2 &&
                                <span className="show-full-model" title={eval(row?.device_models).toString()}>
                                    {`+${eval(row?.device_models)?.toString()?.split(',')?.length > 2
                                        ? eval(row?.device_models)?.toString()?.split(',')?.length - 2
                                        : ''}`}
                                </span>
                            }
                        </span>
                    ),
                },
                {
                    name: "Severity Level",
                    selector: "severity_level",
                    value: "Severity Level",
                    center: true,
                    cell: (row) => (<span title={row?.severity_level} style={{ width: 'auto' }}>
                        {row?.severity_level}</span>
                    ),
                },
                {
                    name: "File Size",
                    selector: "file_size",
                    value: "file_size",
                    center: true,
                    cell: (row) => (<span title={row?.file_size} style={{ width: 'auto' }}>
                        {row?.file_size}</span>
                    ),
                },
                {
                    name: "Estimated Time",
                    selector: "estimated_uptime",
                    value: "Estimated Time",
                    cell: (row) => (<span title={row?.estimated_uptime} style={{ width: 'auto' }}>
                        {row?.estimated_uptime}</span>
                    ),
                },
                {
                    name: "Firmware Date",
                    selector: "fw_date",
                    value: "Firmware Date",
                    cell: (row) => (<span title={this.getTimeExact(row?.fw_date, 'DD MMMM YYYY')} style={{ width: 'auto' }}>
                        {this.getTimeExact(row?.fw_date, 'DD MMMM YYYY')}</span>
                    ),
                },
                {
                    value: "Action",
                    center: true,
                    cell: (row) => (
                        <div className="action-btn">
                            <button
                                className={
                                    (this.props?.userRole === "Viewer_Group" ||
                                        this.props?.userRole === "Operator_Group")
                                        ? "btn btn-primary disabled"
                                        : "btn btn-primary"
                                }
                                onClick={
                                    (this.props?.userRole === "Viewer_Group" ||
                                        this.props?.userRole === "Operator_Group")
                                        ? undefined
                                        : () => this.handleUpdateFirmware(row)}> Update firmware</button>
                        </div>
                    ),
                },
            ]
        }
    };

    componentDidMount() {
        this.getDeviceModel();
        if (this.props?.location?.state?.data) {
            this.setState({
                deviceModel: this.props?.location?.state?.data || "",
                filterEnabled: true
            }, () =>
                this.getFirmwareListFilter()
            )
        }
        else {
            this.getListFirmware();
        }
    };

    getFirmwareVersions = (e) => {
        if (e) {
            this.setState({ deviceModel: e.target.value })
        }
        let data = {
            "device_model": e ? e.target.value : this.state.deviceModel
        }
        this.setState({ showLoader: true })
        ApiCall(urlMapping.getFirmwareVersions(data), (response) => {
            this.setState({ showLoader: false })
            if (response?.data) {
                this.setState({
                    firmwareVersionData: response?.data,
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    };

    getListFirmware = () => {
        if (this.state.filterEnabled) {
            this.getFirmwareListFilter()
        }
        else {
            this.setState({ showLoader: true })
            ApiCall(urlMapping.getListFirmware(this.state.pageNo, this.state.pageSize), (response) => {
                this.setState({ showLoader: false })
                if (response?.count) {
                    this.setState({
                        firmwareData: response?.results,
                        count: response?.count,
                    })
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            })
        }
    };

    resetPopup = () => {
        this.setState(
            {
                show: false,
                error: false,
            },
            () => {
                this.getListFirmware()
                $("#errorMessage").modal("hide")
            }
        );
    };

    getDeviceModel = () => {
        this.setState({ showLoader: true })
        ApiCall(urlMapping.getDeviceModelDashboard(), (response) => {
            this.setState({ showLoader: false })
            if (response?.success) {
                this.setState({ deviceModelFilter: response?.data });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    };

    paginationChange = (e) => {
        if (this.state.deviceModel !== "") {
            this.setState({
                pageNo: e
            }, () => {
                this.getFirmwareListFilter();
            });
        }
        else {
            this.setState({
                pageNo: e
            }, () => {
                this.getListFirmware();
            });
        }
    };

    pageSizeChange = (e) => {
        if (this.state.deviceModel !== "") {
            this.setState({
                pageSize: e
            }, () => {
                this.getFirmwareListFilter();
            });
        }
        else {
            this.setState({
                pageSize: e
            }, () => {
                this.getListFirmware();
            });
        }
    };

    getTimeExact = (time, format) => {
        let date = moment.utc(time);
        date = date.local();
        return date.format(format);
    };

    handleUpdateFirmware = (row) => {
        const headendcpe = row.is_headend_cpe === true ? "1" : "0";
        this.setState({
            selectedData: row,
            headendcpe
        })
        this.props.dispatch({
            type: Action.OPEN_UPDATE_FIRMWARE,
        });
    };

    closeUpdateFirmware = () => {
        this.props.dispatch({
            type: Action.CLOSE_UPDATE_FIRMWARE,
        });
    };

    setFilterList = () => {
        this.setState((prevState) => ({
            showFilter: !prevState.showFilter,
        }));
    };

    hideDropdown = (e) => {
        var popup = $("#parentDiv");
        if (!$('#open').is(e.target) && !popup.is(e.target) && popup.has(e.target).length === 0) {
            this.setState({
                showFilter: false,
                deviceModel: this.state.showIcon ? this.state.deviceModel : "",
                firmwareVersion: this.state.showIcon ? this.state.firmwareVersion : ''
            })
        }
    };

    clearDropdown = (type) => {
        if (type === "Model") {
            this.setState({ deviceModel: "", firmwareVersion: '' })
        }
        else {
            this.setState({ firmwareVersion: '' })
        }
    };

    resetValue = () => {
        this.setState({
            deviceModel: '',
            firmwareVersion: '',
            filterEnabled: false,
            showIcon: false,
            showFilter: false
        }, () => this.getListFirmware())
    };

    setDeviceModel = (e) => {
        if (e.target.value !== 'select') {
            this.setState({ deviceModel: e.target.value });
            this.state.firmwareData.filter((version) => {
                return version.device_models.toString().split(',') === this.state.deviceModel;
            })
        }
    };

    setFirmwareVersion = (e) => {
        if (e.target.value !== 'select') {
            this.setState({ firmwareVersion: e.target.value });
        }
    };

    getFirmwareListFilter = () => {
        this.setState({ showLoader: true })
        let data = '';
        if (this.state.deviceModel) {
            data = {
                device_models: this.state.deviceModel,
                fw_version: this.state.firmwareVersion
            };
            ApiCall(urlMapping.getFirmwareListFilter(data, this.state.pageNo, this.state.pageSize), (response) => {
                this.setState({ showLoader: false })
                if (response?.results) {
                    // LONG-1518_count,data is set for filter list
                    this.setState({
                        count: response?.count,
                        firmwareData: response?.results?.data,
                        showFilter: false,
                        showIcon: true
                    })
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            })
        }
    };

    openPopUp = () => {
        this.setState({
            selectedFile: null,
            showPopup: true
        }, () => {
            this.stepInput.current.value = null;
            $("#versionErrorMessage").modal("show");
        })
    };

    closePopUp = () => {
        this.setState({
            selectedFile: null
        }, () => {
            this.stepInput.current.value = null;
        })
    };

    handleselect = (e) => {
        const file = e.target.files[0];
        this.setState({ selectedFile: file });
    };

    uploadDebianFile = () => {
        const formData = new FormData();
        formData.append("file", this.state.selectedFile);
        formData.append("firmware", "True");
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.deviceListUpload(formData), (response) => {
            if (response?.success) {
                this.setState({
                    showLoader: false,
                    selectedFile: null,
                    title: 'Firmware Upload',
                    message: "Firmware file uploaded successfully!",
                    show: true,
                    error: false,
                }, () => {
                    $("#errorMessage").modal("show");
                });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                        title: 'Firmware Upload',
                        message: response?.message || "Failed",
                        show: true,
                        error: true,
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                }
            }
        })
    };

    render() {
        return (
            <div onMouseDown={(e) => this.hideDropdown(e)}>
                <SideMenu />
                {this.state.showLoader && <Loader />}
                <section id="rhsMain" className={this.props.open ? "rhs-main" : "rhs-main expand-w"}>
                    <Header />
                    <div className="white-bg">
                        <div className="ta-box">
                            <h1 className="page-title">Device Firmware</h1>
                            <div className="action-btn">
                                <button
                                    onClick={() => this.getListFirmware()}>
                                    <img src="/images/svg-icons/refreshing.svg" alt="" />&nbsp;Refresh</button>
                                <button onClick={this.openPopUp}>Browse for Sync</button>
                                <div className="btn-group" >
                                    {/* disabled filter button if no data */}
                                    <button
                                        type="button"
                                        className="dropdown-toggle  filter-btn"
                                        disabled={(this.state.firmwareData.length === 0) && !this.state.showIcon}
                                        onClick={() => this.setFilterList()}
                                    >
                                        {
                                            this.state.showIcon
                                                ? <img src="/images/svg-icons/info-icon.svg" alt="" />
                                                : <img src="/images/svg-icons/filter.svg" alt="" />
                                        }
                                        Filter</button>
                                    {
                                        this.state.showFilter
                                        && <div
                                            className={
                                                this.state.showFilter
                                                    ? "dropdown-menu show  dropdown-menu-right filter-section width360"
                                                    : "dropdown-menu dropdown-menu-right filter-section width360"
                                            }
                                            id="parentDiv">
                                            <div className="form-group">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label>Device Model</label>
                                                    <span onClick={() => this.clearDropdown("Model")}>Clear</span>
                                                </div>
                                                <select
                                                    className="form-control"
                                                    value={this.state.deviceModel ? this.state.deviceModel : ""}
                                                    onChange={(e) => this.getFirmwareVersions(e)}
                                                >
                                                    <option value="">Select Device Model</option>
                                                    {
                                                        this.state.deviceModelFilter.map((model, key) => {
                                                            if (model.model_name !== "")
                                                                return (<option key={key} value={model?.model_name}>{model?.model_name}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className={(this.state.deviceModel === "") ? "form-group disabled" : "form-group"}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label>Firmware Version</label>
                                                    <span
                                                        onClick={() => (this.state.deviceModel === "") ? undefined : this.clearDropdown()}
                                                        style={{ cursor: (this.state.deviceModel === "") ? "not-allowed" : "" }}
                                                    >Clear</span>
                                                </div>
                                                <select
                                                    className="form-control"
                                                    disabled={(this.state.deviceModel === "")}
                                                    value={this.state.firmwareVersion ? this.state.firmwareVersion : ""}
                                                    onChange={(e) => this.setFirmwareVersion(e)}
                                                    style={{ cursor: (this.state.deviceModel === "") ? "not-allowed" : "" }}
                                                >
                                                    <option value="">Select Firmware Version</option>
                                                    {
                                                        this.state.firmwareVersionData.map((item) => {
                                                            return (<option value={item} >{item}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="action-box">
                                                <button
                                                    className="btn btn-light"
                                                    onClick={() => this.resetValue()}
                                                >Reset</button>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        this.setState({
                                                            pageNo: 1,
                                                            pageSize: 10,
                                                            filterEnabled: true
                                                        }, () => this.getFirmwareListFilter())
                                                    }}
                                                    disabled={
                                                        (this.state.deviceModel === this.state.firmwareVersion) ||
                                                        this.state.deviceModel === ""
                                                    }
                                                >Apply</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div>
                            <DataTable
                                columns={this.state.columns}
                                data={this.state.firmwareData}
                                highlightOnHover
                                fixedHeader
                                pagination
                                paginationServer
                                paginationTotalRows={this.state.count}
                                onChangePage={(e) => this.paginationChange(e)}
                                onChangeRowsPerPage={(e) => this.pageSizeChange(e)}
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                            />
                        </div>
                        {
                            this.state.showPopup &&
                            <div
                                className="modal fade errorMessage"
                                id="versionErrorMessage"
                                tabIndex="-1"
                                data-backdrop="static"
                                role="dialog"
                                aria-labelledby="errorMessageTitle"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header bg-white border-0">
                                        </div>
                                        <div className="s-header">
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                style={{ paddingRight: "20px" }}
                                                onClick={() => this.closePopUp()}>
                                                <img src="/images/svg-icons/cancel.svg" alt="" height="15px" width="15px" />
                                            </button>
                                        </div>
                                        <div className="modal-body pt-5">
                                            <div className="error-msg">
                                                <span className="error-icon">i</span>
                                                <div className="txt1">Do you want to upload firmware?</div>
                                                <input
                                                    className="f1 mt-2"
                                                    style={{ marginLeft: "4.5rem", cursor: "pointer" }}
                                                    type="file"
                                                    accept={".zip"}
                                                    ref={this.stepInput}
                                                    onChange={(e) => this.handleselect(e)}
                                                />
                                            </div>
                                            {
                                                (
                                                    this.state.selectedFile === undefined ||
                                                    this.state.selectedFile === null ||
                                                    !this.state.selectedFile?.name?.includes('.zip')
                                                )
                                                &&
                                                <div className="d-flex justify-content-center red-txt mt-3">
                                                    Please upload a ZIP file!
                                                </div>
                                            }
                                        </div>
                                        <div className="modal-footer border-top-0 justify-content-center pb-4">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                data-dismiss="modal"
                                                disabled={
                                                    this.state.selectedFile === undefined ||
                                                    this.state.selectedFile === null ||
                                                    !this.state.selectedFile?.name?.includes('.zip')
                                                }
                                                onClick={() => this.uploadDebianFile()}
                                            > Sync
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.show &&
                            <ErrorPopup
                                title={this.state.title}
                                message={this.state.message}
                                error={this.state.error}
                                reset={() => this.resetPopup()}
                            />
                        }
                        {
                            this.state.isAxiosError &&
                            <ErrorPopup
                                title={this.state.title}
                                message={this.state.message}
                                error={this.state.error}
                                delete={this.state.delete}
                                reset={() => this.setState({ isAxiosError: false })}
                            />
                        }
                    </div>
                    {
                        this.props.openUpdateFirmware &&
                        <UpdateFirmware
                            data={this.state.selectedData}
                            isheadendcpe={this.state.headendcpe}
                            closeUpdateFirmware={() => this.closeUpdateFirmware()}
                            props={this.props} />
                    }
                </section>
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        openUpdateFirmware: state.displayUpdateFirmware.openUpdateFirmware,
        userRole: String(state.auth.role)
    });
};

export default withRouter(connect(mapStateToProps)(DeviceFirmware));
